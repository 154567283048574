<template>
  <div class="home">
    <DashboardData />
    <DashboardTable />
    <DashboardCharts />
  </div> 
</template>
<script>
  import { onMounted } from 'vue'
  import DashboardData from '@/components/dashboard/DashboardData';
  import DashboardTable from '@/components/dashboard/DashboardTable';
  import DashboardCharts from '@/components/dashboard/DashboardCharts';

  export default {
    name: 'Dashboard',
    components: {
      DashboardData,
      DashboardTable,
      DashboardCharts
    },
    data () {
      return {}
    },
    setup() {
      onMounted(async () => {})

      return {}
    }
  }
</script>

<style lang="scss" scoped>
  .home{
    background: white;
  }

  .ant-table-row::v-deep {
    height: 31px!important;
  }
</style>